<template>
  <identify-register :typePage="typePage"></identify-register>
</template>

<script>
import IdentifyRegister from "@/views/modules/collection/business/collIdentification/identifyRegister.vue";

export default {
    name: "identifyReview",
    components: {IdentifyRegister},
    data(){
        return{
            typePage:'identifyReview',
        }
    },
}
</script>

<style scoped>

</style>